import * as React from 'react'
import IconCreditCard from '@veneer/core/dist/esm/scripts/icons/icon_credit_card'
import IconDocument from '@veneer/core/dist/esm/scripts/icons/icon_document'
import IconIdCardInfo from '@veneer/core/dist/esm/scripts/icons/icon_id_card_info'
import IconEnvelopeOpen from '@veneer/core/dist/esm/scripts/icons/icon_envelope_open'
import IconTruck from '@veneer/core/dist/esm/scripts/icons/icon_truck'
import IconReportAlert from '@veneer/core/dist/esm/scripts/icons/icon_report_alert'
import IconLockAccount from '@veneer/core/dist/esm/scripts/icons/icon_lock_account'
import IconCloud from '@veneer/core/dist/esm/scripts/icons/icon_cloud'
import IconLaptop from '@veneer/core/dist/esm/scripts/icons/icon_laptop'
import IconPower from '@veneer/core/dist/esm/scripts/icons/icon_power'
import IconSpeakerVolumeUp from '@veneer/core/dist/esm/scripts/icons/icon_speaker_volume_up'
import IconMonitor from '@veneer/core/dist/esm/scripts/icons/icon_monitor'
import IconTopCoverUnlock from '@veneer/core/dist/esm/scripts/icons/icon_top_cover_unlock'
import IconDatabase from '@veneer/core/dist/esm/scripts/icons/icon_database'
import IconRestore from '@veneer/core/dist/esm/scripts/icons/icon_restore'
import IconGear from '@veneer/core/dist/esm/scripts/icons/icon_gear'
import IconApplicationGear from '@veneer/core/dist/esm/scripts/icons/icon_application_gear'
import IconKeyboard from '@veneer/core/dist/esm/scripts/icons/icon_keyboard'
import IconPrinterWarning from '@veneer/core/dist/esm/scripts/icons/icon_printer_warning'
import IconCopies from '@veneer/core/dist/esm/scripts/icons/icon_copies'
import IconFax from '@veneer/core/dist/esm/scripts/icons/icon_fax'
import IconPhonePrint from '@veneer/core/dist/esm/scripts/icons/icon_phone_print'
import IconDocumentScan from '@veneer/core/dist/esm/scripts/icons/icon_document_scan'
import IconCalendarInk from '@veneer/core/dist/esm/scripts/icons/icon_calendar_ink'
import IconWifi100 from '@veneer/core/dist/esm/scripts/icons/icon_wifi_100'
import IconPrinterCheckmark from '@veneer/core/dist/esm/scripts/icons/icon_printer_checkmark'
import IconPrinterMinus from '@veneer/core/dist/esm/scripts/icons/icon_printer_minus'
import IconDocumentInkDrop from '@veneer/core/dist/esm/scripts/icons/icon_document_ink_drop'
import IconReceiptPrinter from '@veneer/core/dist/esm/scripts/icons/icon_receipt_printer'
import IconNetwork from '@veneer/core/dist/esm/scripts/icons/icon_network'
import IconInfo from '@veneer/core/dist/esm/scripts/icons/icon_info'
import IconPrinterAdd from '@veneer/core/dist/esm/scripts/icons/icon_printer_add'
import IconPrinterX from '@veneer/core/dist/esm/scripts/icons/icon_printer_x'
// import IconPackagePacked from '@veneer/core/dist/esm/scripts/icons/icon_package_packed'
// import IconPrintSingleSided from '@veneer/core/dist/esm/scripts/icons/icon_print_single_sided'
import IconInk from '@veneer/core/dist/esm/scripts/icons/icon_ink'
// import IconRedo from '@veneer/core/dist/esm/scripts/icons/icon_redo'
import IconPerson from '@veneer/core/dist/esm/scripts/icons/icon_person'
import IconPrinterHelp from '@veneer/core/dist/esm/scripts/icons/icon_printer_help'
import { printerRegedit } from './printerRegeditArray'

function onAvoidTIsNotFunctionError(t: any, text: string) {
  if (t === null || t === undefined) {
    return ''
  } else {
    if (typeof t === 'function') {
      // TODO: fix this issue in next sprint as we don't have all of languages translations string.
      return text == 'common.cannotPrint'
        ? t(text, 'HP All-In Can not print')
        : t(text)
    }
  }
}

export const vaData = {
  countries: [
    'US',
    'CA',
    'IE',
    'GB',
    'IN',
    'SG',
    'AU',
    'NZ',
    'AM',
    'BW',
    'EG',
    'ID',
    'IR',
    'IQ',
    'JO',
    'KE',
    'KW',
    'LV',
    'LR',
    'LT',
    'MY',
    'NA',
    'NG',
    'PH',
    'QA',
    'RO',
    'SA',
    'RS',
    'SL',
    'SK',
    'SI',
    'ZA',
    'TZ',
    'UG',
    'AE',
    'VN',
    'ZM',
    'ZW',
    'TH',
    'NL',
    'BE',
    'NO',
    'FI',
    'SE',
    'DK',
    'AT',
    'DE',
    'CH',
    'BY',
    'CY',
    'EE',
    'GR',
    'KZ',
    'LY',
    'OM',
    'PS',
    'RU',
    'SY',
    'YE',
    'ES',
    'MX',
    'AR',
    'CO',
    'CL',
    'PE',
    'EC',
    'BO',
    'VE',
    'PR',
    'UY',
    'PY',
    'BZ',
    'CR',
    'SV',
    'GT',
    'HN',
    'NI',
    'PA',
    'BJ',
    'BF',
    'BI',
    'CM',
    'CF',
    'TD',
    'KM',
    'CG',
    'CD',
    'CI',
    'DJ',
    'FR',
    'GF',
    'PF',
    'TF',
    'GA',
    'GN',
    'HT',
    'MG',
    'ML',
    'MC',
    'NE',
    'RW',
    'SN',
    'SC',
    'TG',
    'VU',
    'LI',
    'LU',
    'AN',
    'SR',
    'AW',
    'BR',
    'PT',
    'MZ',
    'AO',
    'AD',
    'GI',
    'AF',
    'CU',
    'CN',
    'JP',
    'IT',
    'TR',
    'KR'
  ],
  languageCodes: [
    'de',
    'en',
    'es',
    'fr',
    'ja',
    'nl',
    'zh-CN',
    'it',
    'tr',
    'pt',
    'ko'
  ],
  getResources(t?: any) {
    return [
      {
        name: 'dz_pcslowissue',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_pcslowissue_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_pcslowissue_Desc'
        ),
        iconType: <IconLaptop />,
        context: 'dz_pcslowissue',
        category: 'Diagnostics'
      },
      {
        name: 'dz_nopowerboot',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_noPowerBoot_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_noPowerBoot_Desc'
        ),
        iconType: <IconPower />,
        context: 'dz_nopowerboot',
        category: 'Diagnostics'
      },
      {
        name: 'dz_soundissue',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_soundIssue_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.dz_soundIssue_Desc'),
        iconType: <IconSpeakerVolumeUp />,
        context: 'dz_soundissue',
        category: 'Diagnostics'
      },
      {
        name: 'dz_displayissue',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_displayIssue_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_displayIssue_Desc'
        ),
        iconType: <IconMonitor />,
        context: 'dz_displayissue',
        category: 'Diagnostics'
      },
      {
        name: 'dz_pclockissue',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_pclockissue_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_pclockissue_Desc'
        ),
        iconType: <IconTopCoverUnlock />,
        context: 'dz_pclockissue',
        category: 'Diagnostics'
      },
      {
        name: 'dz_storageissue',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_storageIssue_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_storageIssue_Desc'
        ),
        iconType: <IconDatabase />,
        context: 'dz_storageissue',
        category: 'Diagnostics'
      },
      {
        name: 'dz_recovery',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_recovery_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.dz_recovery_Desc'),
        iconType: <IconRestore />,
        context: 'dz_recovery',
        category: 'Diagnostics'
      },
      {
        name: 'dz_operatingsystem',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_operatingSystem_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_operatingSystem_Desc'
        ),
        iconType: <IconGear />,
        context: 'dz_operatingsystem',
        category: 'Diagnostics'
      },
      {
        name: 'dz_winloginissues',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_winloginissues_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.dz_winloginissues_Desc'
        ),
        iconType: <IconApplicationGear />,
        context: 'dz_winloginissues',
        category: 'Diagnostics'
      },
      {
        name: 'dz_kbMouseDT',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_kbMouseDT_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.dz_kbMouseDT_Desc'),
        iconType: <IconKeyboard />,
        context: 'dz_keyboardtouchpad',
        category: 'Diagnostics'
      },
      {
        name: 'pts_wifi',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_pts_wifi_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.dz_pts_wifi_Desc'),
        iconType: <IconPrinterWarning />,
        context: 'pts_wifi',
        category: 'Diagnostics'
      },
      {
        name: 'dz_pcwireless',
        title: onAvoidTIsNotFunctionError(t, 'common.dz_pcwireless_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.dz_pcwireless_Desc'),
        iconType: <IconNetwork />,
        context: 'dz_pcwireless',
        category: 'Diagnostics'
      },
      {
        name: 'PrintJobStuckTroubleshooting',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.PrintJobStuckTroubleshooting_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.PrintJobStuckTroubleshooting_Desc'
        ),
        iconType: <IconPrinterWarning />,
        context: 'pts_JobStuck',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'USB2Wireless',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_USB2Wireless_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.txt_USB2Wireless_Desc'
        ),
        iconType: <IconWifi100 />,
        context: 'pts_usb2wireless',
        category: 'Printer_Diagnostics',
        child: 'ShowPT_USB2Wireless',
        childRegedits: printerRegedit.ShowPT_USB2Wireless
      },
      {
        name: 'Copy',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_Copy_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_Copy_Desc'),
        iconType: <IconCopies />,
        context: 'pts_copy',
        category: 'Printer_Diagnostics',
        child: 'ShowPT_Copy',
        childRegedits: printerRegedit.ShowPT_Copy
      },
      {
        name: 'CarriageJam',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_CarriageJam_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.txt_CarriageJam_Desc'
        ),
        iconType: <IconPrinterWarning />,
        context: 'pts_carriagejam',
        category: 'Printer_Diagnostics',
        child: 'ShowPT_CarriageJam',
        childRegedits: printerRegedit.ShowPT_CarriageJam
      },
      {
        name: 'FaxErrors',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_FaxErrors_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_FaxErrors_Desc'),
        iconType: <IconFax />,
        context: 'pts_faxerrors',
        category: 'Printer_Diagnostics',
        child: 'ShowPT_FaxErrors',
        childRegedits: printerRegedit.ShowPT_FaxErrors
      },
      {
        name: 'pts_PrinterSetup',
        title: onAvoidTIsNotFunctionError(t, 'common.pts_PrinterSetup_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrinterSetup_Desc'
        ),
        iconType: <IconPrinterCheckmark />,
        context: 'pts_PrinterSetup',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'WirelessNetwork',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.txt_WirelessNetwork_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.txt_WirelessNetwork_Desc'
        ),
        iconType: <IconPhonePrint />,
        context: 'pts_wirelessnetwork',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'pts_Offline',
        title: onAvoidTIsNotFunctionError(t, 'common.pts_Offline_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.pts_Offline_Desc'),
        iconType: <IconPrinterMinus />,
        context: 'pts_Offline',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'Scan',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_Scan_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_Scan_Desc'),
        iconType: <IconDocumentScan />,
        context: 'pts_scan',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'pts_PrintingBlackColor',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrintingBlackColor_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrintingBlackColor_Desc'
        ),
        iconType: <IconDocumentInkDrop />,
        context: 'pts_PrintingBlackColor',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'pts_InkCartridgeError',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.pts_InkCartridgeError_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_InkCartridgeError_Desc'
        ),
        iconType: <IconCalendarInk />,
        context: 'pts_InkCartridgeError',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'PaperJam',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_PaperJam_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_PaperJam_Desc'),
        iconType: <IconReceiptPrinter />,
        context: 'pts_paperjam',
        category: 'Printer_Diagnostics'
      },
      {
        name: 'HPOne_ChangeBillInfo',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneChangeBillingInformation'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneChangeBillingInformation'
        ),
        iconType: <IconCreditCard />,
        context: 'HPOne_ChangeBillInfo',
        category: 'AccountSupport',
        hardCodeResources: [
          // {
          //   name: t(
          //     'common.visticMyAccountSection',
          //     'Visit my account section'
          //   ),
          //   title: t(
          //     'common.visticMyAccountSection',
          //     'Visit my account section'
          //   ),
          //   description: t(
          //     'common.visticMyAccountSection',
          //     'Visit my account section'
          //   ),
          //   context: t(
          //     'common.visticMyAccountSection',
          //     'Visit_My_Account_Section'
          //   ),
          //   deepLink: 'hpx://account',
          //   automationID: 'Visit_My_Account_Section'
          // },
          {
            name: onAvoidTIsNotFunctionError(
              t,
              'common.hpOneSubscriptionInformation'
            ),
            title: onAvoidTIsNotFunctionError(
              t,
              'common.hpOneSubscriptionInformation'
            ),
            description: onAvoidTIsNotFunctionError(
              t,
              'common.hpOneSubscriptionInformation'
            ),
            context: onAvoidTIsNotFunctionError(
              t,
              'common.hpOneSubscriptionInformation'
            ),
            deepLink:
              '"HPSALauncher.exe" hponesubscriptioninformation %serialNumber% /UseCCC',
            automationID: 'HP_One_Subscription_Information'
          },
          {
            name: onAvoidTIsNotFunctionError(t, 'common.contactHPSupport'),
            title: onAvoidTIsNotFunctionError(t, 'common.contactHPSupport'),
            description: onAvoidTIsNotFunctionError(
              t,
              'common.contactHPSupport'
            ),
            context: onAvoidTIsNotFunctionError(t, 'common.contactHPSupport'),
            deepLink: 'hpx://support/device',
            automationID: 'Contact_HP_Support'
          }
        ]
      },
      {
        name: 'HPOne_DeniedCredit',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneCreditDenial'),
        description: onAvoidTIsNotFunctionError(t, 'common.hpOneCreditDenial'),
        iconType: <IconIdCardInfo />,
        context: 'HPOne_DeniedCredit',
        category: 'AccountSupport'
      },
      {
        name: 'HPOne_ViewBillinfo',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneUnderstantMyBill'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneUnderstantMyBill'
        ),
        iconType: <IconDocument />,
        context: 'HPOne_ViewBillinfo',
        category: 'AccountSupport',
        isHide: true
      },
      {
        name: 'HPOne_ChangeEmail',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneChangeEmailAddress'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneChangeEmailAddress'
        ),
        iconType: <IconEnvelopeOpen />,
        context: 'HPOne_ChangeEmail',
        category: 'AccountSupport'
      },
      {
        name: 'HPOne_ChangeShipAddress',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneChangeShippingAddress'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneChangeShippingAddress'
        ),
        iconType: <IconTruck />,
        context: 'HPOne_ChangeShipAddress',
        category: 'AccountSupport',
        isHide: true
      },
      {
        name: 'HPOne_CancelAccount',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneCancelMyAccount'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneCancelMyAccount'
        ),
        iconType: <IconPerson />,
        context: 'HPOne_CancelAccount',
        category: 'AccountSupport'
      },
      {
        name: 'HPOne_AccountError',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneAccountErrorMessage'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneAccountErrorMessage'
        ),
        iconType: <IconReportAlert />,
        context: 'HPOne_AccountError',
        category: 'AccountSupport',
        isHide: true
      },
      {
        name: 'HPOne_LoginIssues',
        title: onAvoidTIsNotFunctionError(t, 'common.hpOneAccountProblems'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.hpOneAccountProblems'
        ),
        iconType: <IconLockAccount />,
        context: 'HPOne_LoginIssues',
        category: 'AccountSupport',
        isHide: true
      },
      {
        name: 'HPOne_MSOneDriveUse',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.GoogleOneAndM365Information'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.GoogleOneAndM365Information'
        ),
        iconType: <IconCloud />,
        context: 'HPOne_GoogleOneM365',
        category: 'MSOneDrive'
        // hardCodeResources: [
        //   {
        //     name: onAvoidTIsNotFunctionError(t, 'common.m365Account'),
        //     title: onAvoidTIsNotFunctionError(t, 'common.m365Account'),
        //     description: onAvoidTIsNotFunctionError(t, 'common.m365Account'),
        //     context: 'M365_Account',
        //     deepLink:
        //       '"HPSALauncher.exe" hponem365account %serialNumber% /UseCCC',
        //     automationID: 'M365_Account'
        //   }
        // ]
      },
      // {
      //   name: 'HPOne_MSOneDriveActivate',
      //   title: onAvoidTIsNotFunctionError(t, 'common.activateMSCloudStorage'),
      //   description: onAvoidTIsNotFunctionError(
      //     t,
      //     'common.activateMSCloudStorage'
      //   ),
      //   iconType: <IconCloud />,
      //   context: 'HPOne_MSOneDriveActivate',
      //   category: 'MSOneDrive',
      //   isHide: true
      // },
      // {
      //   name: 'HPOne_MSOneDriveInstall',
      //   title: onAvoidTIsNotFunctionError(t, 'common.installMSCloudStorage'),
      //   description: onAvoidTIsNotFunctionError(
      //     t,
      //     'common.installMSCloudStorage'
      //   ),
      //   iconType: <IconCloud />,
      //   context: 'HPOne_MSOneDriveInstall',
      //   category: 'MSOneDrive',
      //   isHide: true
      // },
      {
        name: 'HPOne_5G_Setup',
        title: onAvoidTIsNotFunctionError(t, 'common.5GModemServiceSetup1'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.5GModemServiceSetup1'
        ),
        iconType: '',
        context: 'HPOne_5G_Setup',
        category: '5GConnectivity'
      },
      {
        name: 'HPOne_5G_Usage',
        title: onAvoidTIsNotFunctionError(t, 'common.5GModemServiceDataUsage1'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.5GModemServiceDataUsage1'
        ),
        iconType: '',
        context: 'HPOne_5G_Usage',
        category: '5GConnectivity'
      },
      {
        name: 'HPOne_5G_TurnOff',
        title: onAvoidTIsNotFunctionError(t, 'common.5GModemServiceTurnOff1'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.5GModemServiceTurnOff1'
        ),
        iconType: '',
        context: 'HPOne_5G_TurnOff',
        category: '5GConnectivity'
      },
      {
        name: 'CannotPrint',
        title: onAvoidTIsNotFunctionError(t, 'common.cannotPrint'),
        description: onAvoidTIsNotFunctionError(t, 'common.cannotPrint'),
        iconType: <IconPrinterHelp />,
        context: 'HPAllIn_CannotPrint',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'PrintJobStuckTroubleshooting',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.PrintJobStuckTroubleshooting_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.PrintJobStuckTroubleshooting_Desc'
        ),
        iconType: <IconPrinterWarning />,
        context: 'pts_JobStuck',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'pts_PrinterSetup',
        title: onAvoidTIsNotFunctionError(t, 'common.pts_PrinterSetup_Title'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrinterSetup_Desc'
        ),
        iconType: <IconPrinterCheckmark />,
        context: 'pts_PrinterSetup',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'WirelessNetwork',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.txt_WirelessNetwork_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.txt_WirelessNetwork_Desc'
        ),
        iconType: <IconPhonePrint />,
        context: 'pts_wirelessnetwork',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'pts_Offline',
        title: onAvoidTIsNotFunctionError(t, 'common.pts_Offline_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.pts_Offline_Desc'),
        iconType: <IconPrinterMinus />,
        context: 'pts_Offline',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'Scan',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_Scan_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_Scan_Desc'),
        iconType: <IconDocumentScan />,
        context: 'pts_scan',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'pts_PrintingBlackColor',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrintingBlackColor_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_PrintingBlackColor_Desc'
        ),
        iconType: <IconDocumentInkDrop />,
        context: 'pts_PrintingBlackColor',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'pts_InkCartridgeError',
        title: onAvoidTIsNotFunctionError(
          t,
          'common.pts_InkCartridgeError_Title'
        ),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.pts_InkCartridgeError_Desc'
        ),
        iconType: <IconCalendarInk />,
        context: 'pts_InkCartridgeError',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'PaperJam',
        title: onAvoidTIsNotFunctionError(t, 'common.txt_PaperJam_Title'),
        description: onAvoidTIsNotFunctionError(t, 'common.txt_PaperJam_Desc'),
        iconType: <IconReceiptPrinter />,
        context: 'pts_paperjam',
        category: 'PrinterAndInkSupport'
      },
      {
        name: 'whatIsHPAllInPlan',
        title: onAvoidTIsNotFunctionError(t, 'common.whatIsHPAllInPlan'),
        description: onAvoidTIsNotFunctionError(t, 'common.whatIsHPAllInPlan'),
        iconType: <IconInfo />,
        context: 'HPAllIn_Description',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_ChangeEmail',
        title: onAvoidTIsNotFunctionError(t, 'common.changeEmailAddress'),
        description: onAvoidTIsNotFunctionError(t, 'common.changeEmailAddress'),
        iconType: <IconEnvelopeOpen />,
        context: 'HPAllIn_ChangeEmail',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_ChangeBillInfo',
        title: onAvoidTIsNotFunctionError(t, 'common.changeBillingInformation'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.changeBillingInformation'
        ),
        iconType: <IconCreditCard />,
        context: 'HPAllIn_ChangeBillInfo',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_ChangeShipAddress',
        title: onAvoidTIsNotFunctionError(t, 'common.changeShippingAddress'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.changeShippingAddress'
        ),
        iconType: <IconTruck />,
        context: 'HPAllIn_ChangeShipAddress',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_AddProduct',
        title: onAvoidTIsNotFunctionError(t, 'common.addAPrinter'),
        description: onAvoidTIsNotFunctionError(t, 'common.addAPrinter'),
        iconType: <IconPrinterAdd />,
        context: 'HPAllIn_AddProduct',
        category: 'HPAllInPlanSupportPeople'
      },
      // {
      //   name: 'HPAllIn_ProductReplacement',
      //   title: onAvoidTIsNotFunctionError(t, 'common.warrantyReplacement'),
      //   description: onAvoidTIsNotFunctionError(
      //     t,
      //     'common.warrantyReplacement'
      //   ),
      //   iconType: <IconPackagePacked />,
      //   context: 'HPAllIn_ProductReplacement',
      //   category: 'HPAllInPlanSupportPeople'
      // },
      // {
      //   name: 'HPAllIn_OrderPaper',
      //   title: onAvoidTIsNotFunctionError(t, 'common.whereMyPaper'),
      //   description: onAvoidTIsNotFunctionError(t, 'common.whereMyPaper'),
      //   iconType: <IconPrintSingleSided />,
      //   context: 'HPAllIn_OrderPaper',
      //   category: 'HPAllInPlanSupportPeople'
      // },
      {
        name: 'HPAllIn_ShipmentStatus',
        title: onAvoidTIsNotFunctionError(t, 'common.whereMyInk'),
        description: onAvoidTIsNotFunctionError(t, 'common.whereMyInk'),
        iconType: <IconInk />,
        context: 'HPAllIn_ShipmentStatus',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_LoginIssues',
        title: onAvoidTIsNotFunctionError(t, 'common.troubleLoggingIn'),
        description: onAvoidTIsNotFunctionError(t, 'common.troubleLoggingIn'),
        iconType: <IconLockAccount />,
        context: 'HPAllIn_LoginIssues',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_AccountError',
        title: onAvoidTIsNotFunctionError(t, 'common.accountErrorMessage'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.accountErrorMessage'
        ),
        iconType: <IconReportAlert />,
        context: 'HPAllIn_AccountError',
        category: 'HPAllInPlanSupportPeople'
      },
      // {
      //   name: 'HPAllIn_ReSubscribe',
      //   title: onAvoidTIsNotFunctionError(t, 'common.reEnrollAfterCancelling'),
      //   description: onAvoidTIsNotFunctionError(
      //     t,
      //     'common.reEnrollAfterCancelling'
      //   ),
      //   iconType: <IconRedo />,
      //   context: 'HPAllIn_ReSubscribe',
      //   category: 'HPAllInPlanSupportPeople'
      // },
      {
        name: 'HPAllIn_CancelAccount',
        title: onAvoidTIsNotFunctionError(t, 'common.cancelMyAccount'),
        description: onAvoidTIsNotFunctionError(t, 'common.cancelMyAccount'),
        iconType: <IconPerson />,
        context: 'HPAllIn_CancelAccount',
        category: 'HPAllInPlanSupportPeople'
      },
      {
        name: 'HPAllIn_RegistrationIssues',
        title: onAvoidTIsNotFunctionError(t, 'common.cannotRegisterPrinter'),
        description: onAvoidTIsNotFunctionError(
          t,
          'common.cannotRegisterPrinter'
        ),
        iconType: <IconPrinterX />,
        context: 'HPAllIn_RegistrationIssues',
        category: 'HPAllInPlanSupportPeople'
      }
    ]
  }
}
